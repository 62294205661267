<template>


  <div class="columns strat1 s-card is-mobile mx-0 my-5">
    <div class="column strat1">
      <div class="flex-table">
        <div class="flex-table-header">
          <span>All Strats ({{ AllStratsP.length }})</span>
          <span class="cell-end">Drag to right&nbsp;<i aria-hidden="true" class="lnir lnir-shift-right"></i></span>
        </div>
        <div class="flex-list-inner">
          <div>
            <draggable ref="AddtoListP" style="width:100%" class="dragArea list-group w-full" :list="AllStratsP" group="stratsp" @change="statusChange('added', 1)">
              <div v-for="(element) in AllStratsP" :key="element.strat_name"
                   class="flex-table-item dragmove has-background-danger">
                <div class="flex-table-cell">
                  {{ element.strat_name }}
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <i aria-hidden="true" class="lnir lnir-shift-right"></i>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div class="column strat1">
      <div class="flex-table">
        <div class="flex-table-header">
          <span><i aria-hidden="true" class="lnir lnir-shift-left"></i>&nbsp;Drag to left</span>
          <span class="cell-end">Custom Strats ({{ CustomStratListP.length }})</span>
        </div>
        <div class="flex-list-inner">
          <div>
            <draggable class="dragArea list-group w-full" :list="CustomStratListP" :move="statusChange('move', 1)" group="stratsp" @change="statusChange('added', 1)">
              <div v-for="element in CustomStratListP" :key="element.strat_name" class="flex-table-item dragmove has-background-info">
                <div class="flex-table-cell">
                  <i aria-hidden="true" class="lnir lnir-shift-left"></i>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  {{ element.strat_name }}
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="user.is_staff==true" class="columns strat2 s-card is-mobile mx-0 my-5">
    <div class="column strat2">
      <div class="flex-table">
        <div class="flex-table-header">
          <span>Yield Strats ({{ AllStratsY.length }})</span>
          <span class="cell-end">Drag to right&nbsp;<i aria-hidden="true" class="lnir lnir-shift-right"></i></span>
        </div>
        <div class="flex-list-inner">
          <div>
            <draggable ref="AddtoListY" class="dragArea list-group w-full" :list="AllStratsY" group="stratsy" @change="statusChange('added', 2)">
              <div v-for="(element) in AllStratsY" :key="element.strat_name" class="flex-table-item dragmove has-background-warning">
                <div class="flex-table-cell has-text-black">
                    {{ element.strat_name }}
                  </div>
                  <div class="flex-table-cell cell-end has-text-black" data-th="">
                    <i aria-hidden="true" class="lnir lnir-shift-right"></i>
                  </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div v-if="user.is_staff==true" class="column strat2">
      <div class="flex-table">
        <div class="flex-table-header">
          <span><i aria-hidden="true" class="lnir lnir-shift-left"></i>&nbsp;Drag to left</span>
          <span class="cell-end">Custom Yields ({{ CustomStratListY.length }})</span>
        </div>
        <div class="flex-list-inner">
          <div>
            <draggable class="dragArea list-group w-full" :list="CustomStratListY" group="stratsy" @change="statusChange('added', 2)">
              <div v-for="element in CustomStratListY" :key="element.strat_name" class="flex-table-item dragmove has-background-info">
                <div class="flex-table-cell">
                  <i aria-hidden="true" class="lnir lnir-shift-left"></i>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  {{ element.strat_name }}
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="user.is_staff==true" class="columns strat3 s-card is-mobile mx-0 my-5">
    <div class="column strat3">
      <div class="flex-table">
        <div class="flex-table-header">
          <span>Indices Strats ({{ AllStratsI.length }})</span>
          <span class="cell-end">Drag to right&nbsp;<i aria-hidden="true" class="lnir lnir-shift-right"></i></span>
        </div>
        <div class="flex-list-inner">
          <div>
            <draggable ref="AddtoListI" class="dragArea list-group w-full" :list="AllStratsI" group="stratsi" @change="statusChange('added', 3)">
              <div v-for="(element) in AllStratsI" :key="element.strat_name" class="flex-table-item dragmove has-background-link">
                <div class="flex-table-cell">
                  {{ element.strat_name }}
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <i aria-hidden="true" class="lnir lnir-shift-right"></i>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div v-if="user.is_staff==true" class="column strat3">
      <div class="flex-table">
        <div class="flex-table-header">
          <span><i aria-hidden="true" class="lnir lnir-shift-left"></i>&nbsp;Drag to left</span>
          <span class="cell-end">Custom Indices ({{ CustomStratListI.length }})</span>
        </div>
        <div class="flex-list-inner">
          <div>
            <draggable class="dragArea list-group w-full" :list="CustomStratListI" group="stratsi" @change="statusChange('added', 3)">
              <div v-for="element in CustomStratListI" :key="element.strat_name" class="flex-table-item dragmove has-background-info">
                <div class="flex-table-cell">
                  <i aria-hidden="true" class="lnir lnir-shift-left"></i>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  {{ element.strat_name }}
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="columns strat2 s-card is-mobile mx-0 my-5">
    <div class="column strat2">
      <div class="flex-table">
        <div class="flex-table-header">
          <span>Alert Strats ({{ AllStratsA.length }})</span>
          <span class="cell-end">Drag to right&nbsp;<i aria-hidden="true" class="lnir lnir-shift-right"></i></span>
        </div>
        <div class="flex-list-inner">
          <div>
            <draggable ref="AddtoListA" style="width:100%" class="dragArea list-group w-full" :list="AllStratsA" group="stratsa" @change="statusChange('added', 4)">
              <div v-for="(element) in AllStratsA" :key="element.strat_name"
                   class="flex-table-item dragmove has-background-danger">
                <div class="flex-table-cell">
                  {{ element.strat_name }}
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <i aria-hidden="true" class="lnir lnir-shift-right"></i>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div class="column strat2">
      <div class="flex-table">
        <div class="flex-table-header">
          <span><i aria-hidden="true" class="lnir lnir-shift-left"></i>&nbsp;Drag to left</span>
          <span class="cell-end">Alert Strats ({{ CustomStratListA.length }})</span>
        </div>
        <div class="flex-list-inner">
          <div>
            <draggable class="dragArea list-group w-full" :list="CustomStratListA" :move="statusChange('move', 1)" group="stratsa" @change="statusChange('added', 4)">
              <div v-for="element in CustomStratListA" :key="element.strat_name" class="flex-table-item dragmove has-background-info">
                <div class="flex-table-cell">
                  <i aria-hidden="true" class="lnir lnir-shift-left"></i>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  {{ element.strat_name }}
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent, inject} from 'vue'
import {VueDraggableNext} from 'vue-draggable-next'
import axios from 'axios'
import {mapState} from "vuex";
import fetch from '../../services/fetch'
import store from '../../store'

export default defineComponent({
  name:'CustomPageBuilder',
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      enabledstratsList: true,
      AllStratsP: [],
      AllStratsY: [],
      AllStratsI: [],
      AllStratsA: [],
      CustomStratListP: [],
      CustomStratListY: [],
      CustomStratListI: [],
      CustomStratListA: [],


      HouseIndicesStratList: [],
      newIndex: Number,
      dragging: true,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
    this.loadSettings()
  },

  methods: {
    loadSettings(){
      if (this.user.id===0){
        if ( store.getters.currentUser ) {
          this.user.id = localStorage.setItem( "uss", store.getters.currentUser.id );

          return {
            userid ,
          }
        }
      }
      const params = new URLSearchParams({
        user: this.user.id,
      }).toString();
      fetch
          .get('/index?' + params)
          .then(response => {
            for (let i = 0; i < response.data['all_strats'].length; i++) {
              switch (response.data['all_strats'][i].is_active) {
                case true:
                  this.AllStratsP.push({
                    id: response.data['all_strats'][i].id,
                    strat_name: response.data['all_strats'][i].strat_name,
                    is_active: response.data['all_strats'][i].is_active,
                    trader: response.data['all_strats'][i].trader,
                    creator: response.data['all_strats'][i].creator,
                    is_yield: response.data['all_strats'][i].is_yield,

                  });
                  this.AllStratsY = this.AllStratsP.slice()
                  this.AllStratsI = this.AllStratsP.slice()
                  this.AllStratsA = this.AllStratsP.slice()

                  break;
                default:
                  this.AllStratsP.push({
                    id: response.data[i].id,
                    strat_name: response.data[i].strat_name,
                    is_active: response.data[i].is_active,
                    trader: response.data[i].trader,
                    creator: response.data[i].creator,
                    is_yield: response.data['all_strats'][i].is_yield,

                  });
                  this.AllStratsY = this.AllStratsP.slice()
                  this.AllStratsI = this.AllStratsP.slice()
                  this.AllStratsA = this.AllStratsP.slice()

              }
            }
            for (let i = 0; i < response.data['custom_strat_list'].length; i++) {
              switch (response.data['custom_strat_list'][i].is_active) {
                case true:
                  this.CustomStratListP.push({
                    id: response.data['custom_strat_list'][i].id,
                    strat_name: response.data['custom_strat_list'][i].strat_name,
                    is_active: response.data['custom_strat_list'][i].is_active,
                    trader: response.data['custom_strat_list'][i].trader,
                    creator: response.data['custom_strat_list'][i].creator,
                    is_yield: response.data['all_strats'][i].is_yield,

                  });

                  break;
                default:
                  this.CustomStratListP = [];

              }

            }

            for (let i = 0; i < response.data['yield_strat_list'].length; i++) {
              switch (response.data['yield_strat_list'][i].is_active) {
                case true:
                  this.CustomStratListY.push({
                    id: response.data['yield_strat_list'][i].id,
                    strat_name: response.data['yield_strat_list'][i].strat_name,
                    is_active: response.data['yield_strat_list'][i].is_active,
                    trader: response.data['yield_strat_list'][i].trader,
                    creator: response.data['yield_strat_list'][i].creator,
                    is_yield: response.data['all_strats'][i].is_yield,

                  });
                  break;
                default:
                  this.CustomStratListY = [];

              }

            }

            for (let i = 0; i < response.data['alert_strat_list'].length; i++) {
              switch (response.data['alert_strat_list'][i].is_active) {
                case true:
                  this.CustomStratListA.push({
                    id: response.data['alert_strat_list'][i].id,
                    strat_name: response.data['alert_strat_list'][i].strat_name,
                    is_active: response.data['alert_strat_list'][i].is_active,
                    trader: response.data['alert_strat_list'][i].trader,
                    creator: response.data['alert_strat_list'][i].creator,
                    is_yield: response.data['all_strats'][i].is_yield,

                  });
                  break;
                default:
                  this.CustomStratListA = [];

              }

            }


            for (let i = 0; i < response.data['indices_strat_list'].length; i++) {
              switch (response.data['indices_strat_list'][i].is_active) {
                case true:
                  this.CustomStratListI.push({
                    id: response.data['indices_strat_list'][i].id,
                    strat_name: response.data['indices_strat_list'][i].strat_name,
                    is_active: response.data['indices_strat_list'][i].is_active,
                    trader: response.data['indices_strat_list'][i].trader,
                    creator: response.data['indices_strat_list'][i].creator,
                    is_yield: response.data['all_strats'][i].is_yield,

                  });
                  break;
                default:
                  this.CustomStratListI = [];

              }

            }
            for (let i = this.AllStratsP.length - 1; i >= 0; i--) {
              for (let j = 0; j < this.CustomStratListP.length; j++) {
                if (this.AllStratsP[i] && (this.AllStratsP[i].strat_name === this.CustomStratListP[j].strat_name)) {
                  this.AllStratsP.splice(i, 1);
                }
              }
            }
            for (let i = this.AllStratsA.length - 1; i >= 0; i--) {
              for (let j = 0; j < this.CustomStratListA.length; j++) {
                if (this.AllStratsA[i] && (this.AllStratsA[i].strat_name === this.CustomStratListA[j].strat_name)) {
                  this.AllStratsA.splice(i, 1);
                }
              }
            }
            for (let i = this.AllStratsY.length - 1; i >= 0; i--) {
              for (let j = 0; j < this.CustomStratListY.length; j++) {
                if (this.AllStratsY[i] && (this.AllStratsY[i].strat_name === this.CustomStratListY[j].strat_name)) {
                  this.AllStratsY.splice(i, 1);
                }
              }
            }
            for (let i = this.AllStratsI.length - 1; i >= 0; i--) {
              for (let j = 0; j < this.CustomStratListI.length; j++) {
                if (this.AllStratsI[i] && (this.AllStratsI[i].strat_name === this.CustomStratListI[j].strat_name)) {
                  this.AllStratsI.splice(i, 1);
                }
              }
            }
          });
    },

    statusChange(event, param) {
      if (event == 'added' || event == 'sorted') {
        let json = {};
        let status = '';
        switch (param) {
          case 1:
            status = 'Full List';
            json = JSON.stringify({
              'custom_strat': this.CustomStratListP,
              'user': this.user.id,
            });
            break;
          case 2:
            status = 'Custom List Yeald';
            json = JSON.stringify({
              'yield_strat': this.CustomStratListY,
              'user': this.user.id,
            });
            break;
          case 3:
            status = 'Custom List Indices';
            json = JSON.stringify({
              'indices_strat': this.CustomStratListI,
              'user': this.user.id,
            });

            break;
          case 4:
            status = 'Alert List Indices';
            json = JSON.stringify({
              'alert_strats': this.CustomStratListA,
              'user': this.user.id,
            });

            break;
        }

        axios.post(`https://p.hifellows.com/api/v1/index/update`, json, {headers: this.headers})
            .then((response) => {
            })
            .catch((error) => {
              alert("An error occurred: ", error);
            });
      }
    },

  },
})
</script>

<style lang="scss" scoped>
@import '../../scss/abstracts/_variables.scss';

.dragmove {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.flex-table {
  .flex-table-header {
    display: flex;
  }

  .flex-list-inner {
    max-height: 50vh;
    overflow-y: scroll;

    .flex-table-item {
      div {
        color: $light;
      }
    }
  }
}


</style>
